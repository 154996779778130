define("spyglass/models/vendor-access", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    company: belongsTo('company'),
    email: attr('string'),
    createdAt: attr('date')
  });

  _exports.default = _default;
});