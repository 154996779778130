define("spyglass/components/survey-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    vendorSurvey: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var formData = this.vendorSurvey.fields.map(function (section) {
        var fields = section.fields.map(function (field) {
          var existingField = _this.surveyResults.findBy('code', field.code);

          if (existingField) {
            return Object.assign({}, field, {
              value: existingField.value
            });
          } else {
            return Object.assign({}, field);
          }
        });
        return {
          name: section.name,
          description: section.description,
          fields: fields
        };
      });
      this.formData = formData;
    },
    actions: {
      onSubmit: function onSubmit() {
        var responses = this.formData.reduce(function (fields, section) {
          return fields.concat(section.fields.map(function (field) {
            return {
              code: field.code,
              value: field.value
            };
          }));
        }, []);
        this.onSubmit(responses);
      }
    }
  });

  _exports.default = _default;
});