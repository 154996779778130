define("spyglass/authenticators/sso", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "spyglass/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: "".concat(_environment.default.API_HOST, "/auth/token"),
    authenticate: function authenticate(identification) {
      var tokenExpiration = 24 * 3600;
      identification['expires_at'] = this._absolutizeExpirationTime(tokenExpiration);
      return new Ember.RSVP.Promise(function (resolve) {
        resolve(identification);
      });
    },
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var now = new Date().getTime();

        if (data.access_token && data.expires_at > now) {
          resolve(data);
        } else {
          reject();
        }
      });
    }
  });

  _exports.default = _default;
});