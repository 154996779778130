define("spyglass/components/survey-results/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    vendorSurvey: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var survey = this.vendorSurvey.fields.map(function (section) {
        var fields = section.fields.map(function (field) {
          var existingField = _this.surveyResults.findBy('code', field.code);

          if (existingField && existingField.value) {
            return {
              label: field.label,
              type: field.type,
              value: existingField.value
            };
          }
        }).compact();

        if (fields.length) {
          return {
            name: section.name,
            fields: fields
          };
        }
      }).compact();
      this.set('survey', survey);
    }
  });

  _exports.default = _default;
});