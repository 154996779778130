define("spyglass/components/trend-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oIqipJL8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"trend\",[23,[\"tagRecord\"]]],[[\"disabled\"],[[23,[\"disabled\"]]]],{\"statements\":[[1,[21,\"tag\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/trend-link/template.hbs"
    }
  });

  _exports.default = _default;
});