define("spyglass/components/search-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ubuiyk3c",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"input\",null,[[\"type\",\"value\",\"placeholder\",\"autocomplete\",\"key-up\"],[\"search\",[23,[\"currentSearchTerm\"]],[23,[\"placeholder\"]],[23,[\"autocomplete\"]],[27,\"perform\",[[23,[\"setSearchTerm\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/search-box/template.hbs"
    }
  });

  _exports.default = _default;
});