define("spyglass/components/user-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QSBmgnAU",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"avatar-user\",null,[[\"size\",\"user\"],[\"medium\",[23,[\"user\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__content\"]]],[9],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__title\"]]],[9],[0,\"\\n    \"],[1,[23,[\"user\",\"fullName\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__subtitle\"]]],[9],[0,\"\\n    \"],[1,[23,[\"user\",\"email\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/user-profile/template.hbs"
    }
  });

  _exports.default = _default;
});