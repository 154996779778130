define("spyglass/profile/company-subscriptions/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    current: Ember.inject.service(),
    model: function model() {
      return this.store.findAll('company-subscription', {
        reload: true
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('companies', model.mapBy('company'));
    }
  });

  _exports.default = _default;
});