define("spyglass/components/role-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zWfzzpA5",
    "block": "{\"symbols\":[\"role\"],\"statements\":[[4,\"single-select\",null,[[\"id\",\"options\",\"selected\",\"searchEnabled\",\"onchange\"],[[23,[\"field\",\"inputId\"]],[23,[\"userRoles\"]],[23,[\"selectedRole\"]],false,[27,\"action\",[[22,0,[]],\"setRole\"],null]]],{\"statements\":[[0,\"  \"],[1,[22,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/role-select/template.hbs"
    }
  });

  _exports.default = _default;
});