define("spyglass/components/score-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZVnhO751",
    "block": "{\"symbols\":[\"score\"],\"statements\":[[4,\"power-select\",null,[[\"options\",\"selected\",\"placeholder\",\"searchEnabled\",\"triggerComponent\",\"onchange\"],[[23,[\"options\"]],[23,[\"selected\"]],[23,[\"placeholder\"]],false,\"filter-trigger\",[27,\"action\",[[22,0,[]],[23,[\"onSelect\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[22,1,[\"min\"]],false],[0,\" – \"],[1,[22,1,[\"max\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/score-select/template.hbs"
    }
  });

  _exports.default = _default;
});