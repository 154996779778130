define("spyglass/components/editable-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8TbESCeZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__content\"]]],[12,\"contenteditable\",[21,\"isEditing\"]],[9],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isShowingPlaceholder\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"type-gray\"],[9],[1,[21,\"placeholder\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"isEditable\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"isEditing\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"flex mt-10\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-primary btn-sm\"],[11,\"type\",\"button\"],[9],[0,\"\\n        Save\\n\"],[4,\"if\",[[23,[\"isSaving\"]]],null,{\"statements\":[[0,\"          \"],[7,\"span\"],[11,\"class\",\"loader\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-secondary btn-sm ml-4\"],[11,\"type\",\"button\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"mt-10\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"btn btn-sm btn-secondary\"],[11,\"role\",\"button\"],[9],[0,\"Edit\"],[3,\"action\",[[22,0,[]],\"edit\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/editable-block/template.hbs"
    }
  });

  _exports.default = _default;
});