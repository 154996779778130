define("spyglass/services/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notify: function notify(error) {
      // Do not log transition aborted errors
      if (error && error.name === 'TransitionAborted') {
        return;
      } // Log in Honeybadger and Log


      if (window.Honeybadger) {
        window.Honeybadger.notify(error);
      }

      console.assert(false, error); // eslint-disable-line no-console
    },
    identify: function identify(user) {
      if (window.Honeybadger) {
        window.Honeybadger.setContext({
          user_id: user.get('id'),
          user_email: user.get('email')
        });
      }
    }
  });

  _exports.default = _default;
});