define("spyglass/components/nav-bar-tabs/tab/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t/6wBM2Q",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"queryParams\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",[[23,[\"path\"]],[23,[\"queryParams\"]]],null,{\"statements\":[[1,[21,\"label\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[4,\"link-to\",[[23,[\"path\"]]],null,{\"statements\":[[1,[21,\"label\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/nav-bar-tabs/tab/template.hbs"
    }
  });

  _exports.default = _default;
});