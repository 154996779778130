define("spyglass/components/sort-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    sortValue: Ember.computed('isAsc', function () {
      if (this.isAsc) {
        return "-".concat(this.sortField);
      } else {
        return this.sortField;
      }
    }),
    isAsc: Ember.computed('sort', 'sortField', function () {
      return this.sort === this.sortField;
    }),
    isDesc: Ember.computed('sort', 'sortField', function () {
      return this.sort === "-".concat(this.sortField);
    })
  });

  _exports.default = _default;
});