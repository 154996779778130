define("spyglass/components/filter-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YcFqi5eJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"value\"],false],[0,\"\\n\"],[7,\"button\"],[11,\"class\",\"btn-icon filter-option-close\"],[11,\"type\",\"button\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],[23,[\"onDelete\"]],[23,[\"value\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/filter-option/template.hbs"
    }
  });

  _exports.default = _default;
});