define("spyglass/edit-user/permission-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wsIp3wK2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__label\"]]],[9],[0,\"\\n  \"],[1,[23,[\"section\",\"name\"]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__toggles\"]]],[9],[0,\"\\n  \"],[7,\"button\"],[12,\"class\",[28,[\"__on \",[21,\"onClass\"]]]],[12,\"disabled\",[23,[\"togglePermission\",\"isRunning\"]]],[11,\"type\",\"button\"],[9],[0,\"\\n    On\\n  \"],[3,\"action\",[[22,0,[]],[27,\"perform\",[[23,[\"togglePermission\"]]],null]]],[10],[0,\"\\n  \"],[7,\"button\"],[12,\"class\",[28,[\"__off \",[21,\"offClass\"]]]],[12,\"disabled\",[23,[\"togglePermission\",\"isRunning\"]]],[11,\"type\",\"button\"],[9],[0,\"\\n    Off\\n  \"],[3,\"action\",[[22,0,[]],[27,\"perform\",[[23,[\"togglePermission\"]]],null]]],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"togglePermission\",\"isRunning\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"loader-overlay\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"loader\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/edit-user/permission-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});