define("spyglass/models/team-note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    company: belongsTo('company'),
    user: belongsTo('user', {
      async: false
    }),
    productionInfo: attr('string'),
    vendorTeamInfo: attr('string'),
    abilityToWorkWithBluePlans: attr('string'),
    otherInfo: attr('string'),
    updatedAt: attr('date'),
    title: Ember.computed('user.{fullName,companyName,email}', function () {
      var user = this.user;

      if (user.get('fullName') && user.get('companyName')) {
        return "".concat(user.get('fullName'), " | ").concat(user.get('companyName'));
      } else if (user.get('fullName')) {
        return user.get('fullName');
      } else {
        return user.get('email');
      }
    })
  });

  _exports.default = _default;
});