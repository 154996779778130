define("spyglass/edit-trend-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t0H9316R",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"breadcrumb bg-white\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[4,\"link-to\",[\"trends\"],null,{\"statements\":[[0,\"Category\"]],\"parameters\":[]},null],[0,\"  >  \\n    \"],[4,\"link-to\",[\"trend-uploads\"],null,{\"statements\":[[0,\"Manage Files\"]],\"parameters\":[]},null],[0,\"  >  \\n    Edit File\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"container mt-30\"],[9],[0,\"\\n  \"],[1,[27,\"trend-upload-form\",null,[[\"upload\",\"afterSave\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"afterSave\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/edit-trend-upload/template.hbs"
    }
  });

  _exports.default = _default;
});