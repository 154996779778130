define("spyglass/components/team-note-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JJ8pEGhc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[9],[0,\"\\n\"],[4,\"form-group\",null,[[\"label\",\"class\"],[\"Production Information\",\"mt-30\"]],{\"statements\":[[0,\"    \"],[1,[27,\"textarea\",null,[[\"value\",\"rows\",\"placeholder\"],[[23,[\"teamNote\",\"productionInfo\"]],\"3\",\"Your response\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"form-group\",null,[[\"label\",\"class\"],[\"Vendor Team Information\",\"mt-30\"]],{\"statements\":[[0,\"    \"],[1,[27,\"textarea\",null,[[\"value\",\"rows\",\"placeholder\"],[[23,[\"teamNote\",\"vendorTeamInfo\"]],\"3\",\"Your response\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"form-group\",null,[[\"label\",\"class\"],[\"Ability to Work with Blue Plans\",\"mt-30\"]],{\"statements\":[[0,\"    \"],[1,[27,\"textarea\",null,[[\"value\",\"rows\",\"placeholder\"],[[23,[\"teamNote\",\"abilityToWorkWithBluePlans\"]],\"3\",\"Your response\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"form-group\",null,[[\"label\",\"class\"],[\"Other Information\",\"mt-30\"]],{\"statements\":[[0,\"    \"],[1,[27,\"textarea\",null,[[\"value\",\"rows\",\"placeholder\"],[[23,[\"teamNote\",\"otherInfo\"]],\"3\",\"Your response\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"form-footer\"],[9],[0,\"\\n    \"],[1,[27,\"submit-button\",null,[[\"label\",\"isSaving\",\"class\"],[[23,[\"submitLabel\"]],[23,[\"save\",\"isRunning\"]],\"btn btn-primary\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[27,\"perform\",[[23,[\"save\"]]],null]],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/team-note-form/template.hbs"
    }
  });

  _exports.default = _default;
});