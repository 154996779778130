define("spyglass/company/new-team-note/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    current: Ember.inject.service(),
    model: function model() {
      return this.store.createRecord('team-note', {
        user: this.get('current.user'),
        company: this.modelFor('company').company
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.currentModel.rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});