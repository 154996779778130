define("spyglass/components/form-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vpUPVIlX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"for\",[23,[\"api\",\"inputId\"]]],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1,[[23,[\"api\"]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"hasError\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__error\"]]],[9],[1,[21,\"formattedError\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/form-group/template.hbs"
    }
  });

  _exports.default = _default;
});