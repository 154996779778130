define("spyglass/trend/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    current: Ember.inject.service(),
    metrics: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('tag', params.tag_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model) {
      this.metrics.trackEvent('Mixpanel', {
        event: 'Viewed Trend',
        name: model.label,
        tag_value: model.value
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        isLoadingCompanies: true,
        isLoadingUploads: true
      });
      this.store.query('company', {
        page: {
          size: 5
        },
        tags: [model.get('label')]
      }).then(function (companies) {
        return controller.set('topCompanies', companies);
      }).finally(function () {
        return controller.set('isLoadingCompanies', false);
      });
      this.store.query('upload', {
        tags: [model.get('label')]
      }).then(function (uploads) {
        return controller.set('uploads', uploads);
      }).finally(function () {
        return controller.set('isLoadingUploads', false);
      });
      var subscriptions = this.get('current.user.tagSubscriptions');
      controller.set('subscription', subscriptions.findBy('tag.id', model.id));
    },
    resetController: function resetController(controller) {
      controller.setProperties({
        topCompanies: [],
        uploads: []
      });
    }
  });

  _exports.default = _default;
});