define("spyglass/company/request-survey/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8m59kXAv",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[4,\"scrollable-modal\",null,[[\"onClose\"],[[27,\"action\",[[22,0,[]],\"close\"],null]]],{\"statements\":[[0,\"  \"],[7,\"h3\"],[11,\"class\",\"type-color\"],[9],[0,\"Provide vendor access to survey\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"type-gray mt-10\"],[9],[0,\"\\n    This will email the vendor and allow them to add or update information in the\\n    vendor section. They will not have access to any other information or data.\\n  \"],[10],[0,\"\\n\\n\"],[4,\"form-group\",null,[[\"label\",\"errors\"],[\"Vendor Email\",[27,\"get\",[[27,\"get\",[[27,\"get\",[[27,\"get\",[[22,0,[]],\"validations\"],null],\"attrs\"],null],\"model.email\"],null],\"messages\"],null]]],{\"statements\":[[0,\"    \"],[1,[27,\"input\",null,[[\"id\",\"value\"],[[22,1,[\"inputId\"]],[23,[\"model\",\"email\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"form-footer\"],[9],[0,\"\\n    \"],[5,\"submit-button\",[[11,\"class\",\"btn btn-primary\"]],[[\"@label\",\"@onClick\",\"@isSaving\",\"@isInvalid\"],[\"Send\",[27,\"perform\",[[22,0,[\"save\"]]],null],[22,0,[\"save\",\"isRunning\"]],[22,0,[\"validations\",\"isInvalid\"]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/company/request-survey/template.hbs"
    }
  });

  _exports.default = _default;
});