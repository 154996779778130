define("spyglass/profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bAWSs0ki",
    "block": "{\"symbols\":[\"tabs\"],\"statements\":[[7,\"div\"],[11,\"class\",\"bg-white\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container pt-40 pb-20\"],[9],[0,\"\\n    \"],[1,[27,\"user-profile\",null,[[\"size\",\"user\"],[\"medium\",[23,[\"current\",\"user\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"bg-white border-bottom\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n\"],[4,\"nav-bar-tabs\",null,null,{\"statements\":[[0,\"      \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"tab\"]],\"expected `tabs.tab` to be a contextual component but found a string. Did you mean `(component tabs.tab)`? ('spyglass/profile/template.hbs' @ L10:C8) \"],null]],[[\"label\",\"path\"],[\"Account\",\"profile.index\"]]],false],[0,\"\\n      \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"tab\"]],\"expected `tabs.tab` to be a contextual component but found a string. Did you mean `(component tabs.tab)`? ('spyglass/profile/template.hbs' @ L14:C8) \"],null]],[[\"label\",\"path\"],[\"Categories Following\",\"profile.trend-subscriptions\"]]],false],[0,\"\\n      \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"tab\"]],\"expected `tabs.tab` to be a contextual component but found a string. Did you mean `(component tabs.tab)`? ('spyglass/profile/template.hbs' @ L18:C8) \"],null]],[[\"label\",\"path\"],[\"Companies Following\",\"profile.company-subscriptions\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"container mt-40\"],[9],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/profile/template.hbs"
    }
  });

  _exports.default = _default;
});