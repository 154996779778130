define("spyglass/components/tag-filter-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    label: Ember.computed.oneWay('tag'),
    tagsArray: Ember.computed('tag', function () {
      return [this.tag];
    })
  });

  _exports.default = _default;
});