define("spyglass/companies/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    appSettings: Ember.inject.service(),
    metrics: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      tags: {
        refreshModel: true
      },
      stages: {
        refreshModel: true
      },
      business: {
        refreshModel: true
      },
      states: {
        refreshModel: true
      },
      regions: {
        refreshModel: true
      },
      industries: {
        refreshModel: true
      },
      lps: {
        refreshModel: true
      },
      startDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      },
      minScore: {
        refreshModel: true
      },
      maxScore: {
        refreshModel: true
      },
      surveyed: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var query = {
        page: {
          number: params.page,
          size: 20
        }
      };

      if (params.search.length > 2) {
        query.search = params.search;
      }

      if (params.tags) {
        query.tags = params.tags;
      }

      if (params.stages) {
        query.stage = params.stages;
      }

      if (params.business) {
        query.business_stage = params.business;
      }

      if (params.states) {
        query.state = params.states;
      }

      if (params.regions) {
        query.region = params.regions;
      }

      if (params.industries) {
        query.industry = params.industries;
      }

      if (params.lps) {
        query.relevant_strategic_lp = params.lps;
      }

      if (params.startDate && params.endDate) {
        var field = this.get('appSettings.lastUpdatedFilterField');
        query[field] = [params.startDate, params.endDate];
      }

      if (params.minScore && params.maxScore) {
        query.weighted_average_score = [params.minScore, params.maxScore];
      }

      if (params.surveyed) {
        query.surveyed = true;
      }

      return {
        companiesPromise: this.store.query('company', query)
      };
    },
    afterModel: function afterModel() {
      this.metrics.trackEvent('Mixpanel', {
        event: 'Viewed Companies'
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      controller.set('isLoading', true);
      model.companiesPromise.then(function (companies) {
        controller.setProperties({
          companies: companies,
          totalPages: companies.get('meta.total_pages'),
          total: companies.get('meta.total'),
          isLoading: false,
          availableTags: _this.store.peekAll('tag').mapBy('label')
        });
      });
    }
  });

  _exports.default = _default;
});