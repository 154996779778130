define("spyglass/company/new-review/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    current: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var user = this.get('current.user');

      if (this.modelFor('company').company.get('reviews').isAny('user', user)) {
        this.transitionTo('company');
      }
    },
    model: function model() {
      return this.store.createRecord('review', {
        user: this.get('current.user'),
        company: this.modelFor('company').company
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.currentModel.rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});