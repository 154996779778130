define("spyglass/components/avatar-company/component", ["exports", "spyglass/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNameBindings: ['sizeClass'],
    size: 'medium',
    sizeClass: Ember.computed('size', function () {
      return {
        small: "".concat(this.styleNamespace, "__sm"),
        medium: "".concat(this.styleNamespace, "__md"),
        large: "".concat(this.styleNamespace, "__lg")
      }[this.size];
    }),
    backgroundColor: Ember.computed(function () {
      return _environment.default.ACTIVE_BACKGROUND_COLOR;
    }),
    textColor: Ember.computed(function () {
      return _environment.default.PRIMARY_COLOR;
    })
  });

  _exports.default = _default;
});