define("spyglass/vendor-survey/route", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UnauthorizedError = _emberData.default.UnauthorizedError;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('vendor-survey', params.id);
    },
    resetController: function resetController(controller) {
      controller.set('hasSubmitted', false);
    },
    actions: {
      error: function error(_error, transition) {
        if (_error instanceof UnauthorizedError) {
          this.transitionTo('vendor-access', transition.to.params.id);
          return;
        }
      }
    }
  });

  _exports.default = _default;
});