define("spyglass/components/avatar-company/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5WtUWlws",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ember-initials\",null,[[\"image\",\"name\",\"size\",\"backgroundColor\",\"textColor\",\"fontFamily\",\"fontWeight\"],[[23,[\"company\",\"logoUrl\"]],[23,[\"company\",\"name\"]],null,[23,[\"backgroundColor\"]],[23,[\"textColor\"]],\"Arial\",400]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/avatar-company/template.hbs"
    }
  });

  _exports.default = _default;
});