define("spyglass/components/bar-chart/component", ["exports", "d3-selection", "d3-scale", "d3-axis"], function (_exports, _d3Selection, _d3Scale, _d3Axis) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    appSettings: Ember.inject.service(),
    tooltipContent: Ember.inject.service(),
    maxValue: 100,
    margin: Object.freeze({
      top: 0,
      right: 0,
      bottom: 20,
      left: 20
    }),
    height: 200,
    width: 300,
    colors: Object.freeze(['#3C90DF', '#61CA00', '#6F7CBA', '#B5B5B5', '#FFB119', '#E73737']),
    tooltipOpacity: 0,
    tooltipRight: 0,
    tooltipTop: 0,
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.run.scheduleOnce('render', this, this.draw);
    },
    tooltipStyle: Ember.computed('tooltipOpacity', 'tooltipRight', 'tooltipTop', function () {
      return ["opacity: ".concat(this.tooltipOpacity), "right: ".concat(this.tooltipRight), "top: ".concat(this.tooltipTop)].join('; ');
    }),
    draw: function draw() {
      var _this = this;

      var data = this.data;
      var margin = this.margin;
      var width = this.width - margin.left - margin.right;
      var height = this.height - margin.top - margin.bottom;
      var colorScale = (0, _d3Scale.scaleOrdinal)().range(this.colors);
      var yScale = (0, _d3Scale.scaleLinear)().domain([0, this.maxValue]).range([height, 0]);
      var xScale = (0, _d3Scale.scaleBand)().domain(data.mapBy('label')).range([0, width]).paddingInner(0.12);
      var svg = (0, _d3Selection.select)(this.$('svg')[0]).append('g').attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")"));
      var showTooltip = this.get('appSettings.showChartTooltip');
      var $tooltip = this.$('.chart-tooltip'); // Background gray bars

      svg.selectAll('.bar-background').data(data).enter().append('rect').attr('class', 'bar-background').attr('width', xScale.bandwidth()).attr('height', height).attr('x', function (dataPoint) {
        return xScale(dataPoint.label);
      }).attr('y', 0).attr('fill', '#F4F7FA'); // Value bars

      svg.selectAll('.bar').data(data).enter().append('rect').attr('class', 'bar').attr('width', xScale.bandwidth()).attr('height', function (dataPoint) {
        return height - yScale(dataPoint.value);
      }).attr('x', function (dataPoint) {
        return xScale(dataPoint.label);
      }).attr('y', function (dataPoint) {
        return yScale(dataPoint.value);
      }).attr('fill', function (_, index) {
        return colorScale(index);
      }).on('mousemove', function (data) {
        if (!showTooltip) {
          return;
        }

        _this.set('tooltipOpacity', 1);

        _this.set('tooltipRight', window.outerWidth - _d3Selection.event.clientX + 8 + 'px');

        _this.set('tooltipTop', _d3Selection.event.clientY - 28 + 'px');

        $tooltip.html(_this.tooltipContent.intellimap(data.label));
      }).on('mouseout', function () {
        if (!showTooltip) {
          return;
        }

        _this.set('tooltipOpacity', 0);
      }); // Bar labels

      svg.selectAll('text').data(data).enter().append('text').text(function (dataPoint) {
        return dataPoint.value;
      }).attr('x', function (dataPoint) {
        return xScale(dataPoint.label) + xScale.bandwidth() / 2;
      }).attr('y', function (dataPoint) {
        return yScale(dataPoint.value) + 20;
      }).attr('font-weight', 600).attr('fill', 'white').attr('text-anchor', 'middle'); // X axis labels

      var xAxis = (0, _d3Axis.axisBottom)().scale(xScale);
      svg.append('g').attr('class', 'axis x-axis').attr('transform', "translate(0,".concat(height, ")")).call(xAxis); // Y axis labels

      var yAxis = (0, _d3Axis.axisLeft)().scale(yScale).tickValues([this.maxValue]).tickSize(20);
      svg.append('g').attr('class', 'axis y-axis').call(yAxis).selectAll('text').attr('x', -4).attr('dy', '1em');
    }
  });

  _exports.default = _default;
});