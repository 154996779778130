define("spyglass/components/company-upload-form/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'upload.url': (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    'upload.name': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Name'
    })
  });

  var _default = Ember.Component.extend(Validations, {
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    afterSave: function afterSave() {},
    actions: {
      save: function save() {
        var _this = this;

        this.upload.save().then(function (upload) {
          _this.flashMessages.success("".concat(upload.get('name'), " saved"));

          _this.afterSave();
        }, function () {
          return _this.flashMessages.danger('Failed to save the file');
        });
      }
    }
  });

  _exports.default = _default;
});