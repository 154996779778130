define("spyglass/vendor-access/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rw10Tz6A",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"panel-layout\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"side-panel\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"main-panel\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"main-panel__table\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"main-panel__table-cell\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"main-panel__content\"],[9],[0,\"\\n          \"],[7,\"h1\"],[9],[0,\"Update Your Profile for Blue Plans\"],[10],[0,\"\\n\"],[4,\"if\",[[22,0,[\"isShowingSuccesss\"]]],null,{\"statements\":[[0,\"            \"],[7,\"p\"],[9],[0,\"\\n              Please check your email to access the survey!\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"p\"],[9],[0,\"Click the button below to send an email to start your secure session.\"],[10],[0,\"\\n\\n            \"],[7,\"div\"],[11,\"class\",\"form-footer\"],[9],[0,\"\\n              \"],[5,\"submit-button\",[[11,\"class\",\"btn btn-primary\"]],[[\"@label\",\"@onClick\",\"@isSaving\"],[\"Send me the link\",[27,\"perform\",[[22,0,[\"createMagicLink\"]]],null],[22,0,[\"createMagicLink\",\"isRunning\"]]]]],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/vendor-access/template.hbs"
    }
  });

  _exports.default = _default;
});