define("spyglass/components/trend-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagRecord: Ember.computed('tag', function () {
      var tagLabel = this.tag;
      var tags = this.store.peekAll('tag');
      return tags.findBy('label', tagLabel);
    }),
    disabled: Ember.computed('tagRecord', function () {
      return Ember.isEmpty(this.tagRecord);
    })
  });

  _exports.default = _default;
});