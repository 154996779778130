define("spyglass/components/star-rating/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    rating: 0,
    stars: Ember.computed('rating', function () {
      var rating = this.rating;
      return [1, 2, 3, 4, 5].map(function (number) {
        return rating >= number ? 'yellow' : 'gray';
      });
    })
  });

  _exports.default = _default;
});