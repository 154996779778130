define("spyglass/components/feedback-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: 'neutral',
    onchange: function onchange() {},
    positiveClass: Ember.computed('value', function () {
      if (this.value === 'positive') {
        return "".concat(this.styleNamespace, "__active");
      }
    }),
    negativeClass: Ember.computed('value', function () {
      if (this.value === 'negative') {
        return "".concat(this.styleNamespace, "__active");
      }
    }),
    neutralClass: Ember.computed('value', function () {
      if (this.value === 'neutral') {
        return "".concat(this.styleNamespace, "__active");
      }
    }),
    actions: {
      switch: function _switch(value) {
        if (this.value !== value) {
          this.onchange(value);
        }
      }
    }
  });

  _exports.default = _default;
});