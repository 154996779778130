define("spyglass/services/vendor-survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PRODUCT_CATEGORIES = [{
    name: 'Ancillary, Voluntary, Specialty',
    secondary: ['Dental', 'Vision', 'Life', 'Insurance Products', 'Internationl/Medical Tourism', 'Medical Transportation', 'Hearing', 'Eldercare', 'Childcare']
  }, {
    name: 'Business Solutions Outsourcing',
    secondary: ['TPA Services', 'Exchange Solutions', 'Call Center', 'Business Plan Outsourcing', "Worker's Comp"]
  }, {
    name: 'Care Delivery',
    secondary: ['Assessments/Testing/Screenings', 'Diagnostics', 'Devices', 'Primary Care', 'Urgent Care', 'Onsite', 'Nearsite', 'Retail', 'Telehealth', 'Virtual Care', 'Kiosks', 'Ambulatory/Outpatient']
  }, {
    name: 'Consumer Engagement',
    secondary: ['Financial', 'Advocacy Services', 'Navigation Services', 'Transparency Tools', 'Plan Selection Tools', 'HSA/FSA', 'Wellness', 'Population Health', 'Physical Activity', 'Weight/Nutrition', 'Provider Directory', 'Appointments/Scheduling', 'EAP', 'Communications', 'Incentives']
  }, {
    name: 'Data & Analytics',
    secondary: ['Data Warehousing', 'Data Analytics', 'Privacy and Data Security']
  }, {
    name: 'Provider/Network Solutions',
    secondary: ['Second Opinion', 'Expert Medical Opinion', 'Clinical Decision Support', 'Complex Care Management', 'ACO', 'PCMH', 'Bundled Care', 'Narrow Networks', 'Specialist Referrals', 'Clinical Trials', 'Centers of Excellence']
  }, {
    name: 'Care/Condition Management',
    secondary: ['Care Coordination', 'Pharmacy', 'Medication Management', 'Behavioral Health', 'Musculoskeletal', 'Oncology', 'Diabetes Prevention Program (DPP)', 'Diabetes', 'Metabolic Syndrome', 'Maternity Management', 'Infertility', 'Weight Management', 'Gender Reassigment', 'Pain Management', 'Genetics', 'Substance Abuse/Addiction', "Children's Health/Pediatrics", 'Autism', 'Sleep', 'Smoking Cessation', 'Renal/Kidney', 'Digestive Conditions']
  }, {
    name: 'Plan Operations/Support',
    secondary: ['Consumer Payment', 'Patient Portals and PHR', 'Patient Satisfaction', 'EMR/EHR', 'Health Plan Operations']
  }, {
    name: 'Cost Containment',
    secondary: ['Subrogation', 'Stop Loss', 'Fraud Waste and Abuse', 'Reinsurance', 'Payment Integrity', 'Utilization Management']
  }];
  var FIELDS = [{
    name: 'Introduction',
    description: 'Consortium Health Plans, which exclusively supports Blue Cross Blue Shield Plans (BCBS Plans), is building a tool that will give BCBS Plans valuable information on innovative solutions like yours, with the goal of better serving their clients and members. By completing this survey, your company and its products and solutions will be included in this new tool for BCBS Plans as they research innovation solutions.',
    fields: [{
      type: 'string',
      label: 'Name',
      description: 'Your name',
      code: 'name'
    }, {
      type: 'string',
      label: 'Email',
      description: 'Your email',
      code: 'email'
    }, {
      type: 'string',
      label: 'Title',
      description: 'Your title',
      code: 'title'
    }]
  }, {
    name: 'Company Background Information',
    description: null,
    fields: [{
      type: 'string',
      label: 'Company Name',
      description: 'What is the company name?',
      code: 'company_name'
    }, {
      type: 'select',
      label: 'Parent Organization',
      description: 'Do you have a parent organization?',
      options: ['Yes', 'No'],
      code: 'has_parent_org'
    }, {
      // TODO: should only be shown if has_parent_org is "Yes".. can we not do that?
      type: 'string',
      label: 'Parent Organization Name',
      description: 'What is the name of the parent organization?',
      code: 'parent_org_name'
    }, {
      type: 'string',
      label: 'Address',
      description: 'Please provide the primary contact info below for your company',
      code: 'address'
    }, {
      type: 'select',
      label: 'Ownership Structure',
      description: 'What is your corporate ownership structure?',
      options: ['Privately owned (for profit)', 'Not for profit', 'Publicly traded', 'Other'],
      code: 'ownership_structure'
    }, {
      type: 'string',
      label: 'Year Founded',
      description: 'What year was the company founded?',
      code: 'year_founded'
    }, {
      type: 'select',
      label: 'Employees',
      description: 'How many employees are there at the company?',
      options: ['1-10', '11-50', '51-200', '201-500', '501-1,000', '1,001-5,000', '5,001-10,000', '10,000+'],
      code: 'employees'
    }, {
      type: 'text',
      label: 'Operating Geographies',
      description: 'Indicate what geographies your product/solution covers (e.g., is it available nationally or only in certain parts of the U.S.?):',
      code: 'operating_geographies'
    }, {
      type: 'text',
      label: 'Capital Raised',
      description: 'Capital raised to date, by series:',
      code: 'capital_raised'
    }, {
      type: 'string',
      label: 'Revenue',
      description: 'Revenue (most recent fiscal year):',
      code: 'revenue'
    }]
  }, {
    name: 'Blue Relationships',
    description: null,
    fields: [{
      type: 'select',
      label: 'Blue Cross Blue Shield owners or investors',
      description: 'Does your company have any Blue Cross Blue Shield owners or investors in your company?',
      options: ['Yes', 'No'],
      code: 'blue_owners_or_investors'
    }, {
      type: 'plans_table',
      label: 'BCBS Plans currently using your product/solution',
      description: 'List BCBS Plans currently using your product/solution. For each Blue Plan, indicate how they are using it',
      value: [],
      code: 'plans_using_solution'
    }]
  }, {
    name: 'Solution Information',
    description: null,
    fields: [{
      type: 'text',
      label: 'Solution Overview',
      description: 'Please provide an overview description of your company, solutions offered, and business, pricing, and billing models',
      code: 'solution_overview'
    }, {
      type: 'select',
      label: 'Primary Product Category',
      description: 'Choose the primary product category',
      options: PRODUCT_CATEGORIES.reduce(function (categories, parentCategory) {
        return categories.concat(parentCategory.name, parentCategory.secondary.map(function (cat) {
          return "- ".concat(cat);
        }));
      }, []),
      code: 'primary_product_category'
    }, {
      type: 'multiselect',
      label: 'Secondary Product Categories',
      description: 'Choose up to 5 secondary product categories. The categories are broken down by primary to make it easier to navigate. You can select across primary categories',
      options: PRODUCT_CATEGORIES.reduce(function (categories, parentCategory) {
        return categories.concat({
          groupName: parentCategory.name,
          options: parentCategory.secondary
        });
      }, []),
      code: 'secondary_product_categories'
    }, {
      type: 'text',
      label: 'Vendors and point solution partners',
      description: 'List any other vendors or point solutions you partner with in offering your product/solution (for example: being accessed via an engagement platform, leveraging telehealth solution to deliver your product, etc.):',
      code: 'vendors_and_point_solution_partners'
    }]
  }, {
    name: 'Market Information',
    description: 'This section focuses on helping BCBS Plans understand your unique market segment and how you differentiate your product.',
    fields: [{
      type: 'text',
      label: 'Competitors',
      description: 'List any competitors in your primary solution category:',
      code: 'competitors'
    }, {
      type: 'text',
      label: 'Differentiation',
      description: 'Describe how you differentiate your solution from the competition:',
      code: 'differentiation'
    }, {
      type: 'text',
      label: 'Primary Target Customer',
      description: 'Who is your primary target customer (e.g., employers, providers, health plans, consumers)? Are there characteristics of a company which would not make your solution a good fit?',
      code: 'primary_target_customer'
    }, {
      type: 'string',
      label: 'Key Customers',
      description: 'Other than BCBS Plans, indicate any key customers (health plans or Fortune 1000 employers) who use your product/solution:',
      code: 'key_customers'
    }, {
      type: 'text',
      label: 'Preferred Partner Relationships',
      description: 'Do you have any preferred partner relationships or have been vetted and approved by any major consulting houses (e.g., Deloitte, AonHewitt, Willis Towers Watson, Mercer, Gallagher)? Do you have other strategic relationships where that company is promoting your product/solution:',
      code: 'preferred_partner_relationships'
    }]
  }, {
    name: 'Clinical Information',
    description: 'This section focuses on helping BCBS Plans understand the clinical components of your product/solution. If your product/solution doesn’t have a clinical component (supporting/managing/providing patient health or treatment), you can skip this section',
    fields: [{
      type: 'text',
      label: 'Clinical Results',
      description: 'Describe the clinical results your product/solution is expected to deliver. Who will experience these results (member, employer, health plan?):',
      code: 'clinical_results'
    }, {
      type: 'select',
      label: 'Published Studies',
      description: 'Do you have any peer reviewed, published studies that validate your clinical outcomes?',
      options: ['Yes', 'No'],
      code: 'published_studies'
    }, {
      type: 'text',
      label: 'Plan Data for Success',
      description: 'What data do you need from the plan to be successful?',
      code: 'clinical_results'
    }, {
      type: 'text',
      label: 'Data Sent to Plan',
      description: 'What data do you send back to the plan?',
      code: 'data_sent_to_plan'
    }]
  }, {
    name: 'Customer and Patient Information',
    description: null,
    fields: [{
      type: 'text',
      label: 'Success Measures',
      description: 'How do you measure success for each stakeholder (customer, patient/member, etc.)? Do you have any performance guarantees based on these metrics?',
      code: 'success_measures'
    }]
  }, {
    name: 'Operational Information',
    description: null,
    fields: [{
      type: 'text',
      label: 'Implementation',
      description: 'At a high level, what is the typical implementation process and timeline?',
      code: 'implementation'
    }, {
      type: 'select',
      label: 'Security',
      description: 'Is your company HITRUST, Soc1 or Soc2 certified?',
      options: ['Yes', 'No', 'Not now but plan to be within 12 months'],
      code: 'security'
    }]
  }];

  var _default = Ember.Service.extend({
    fields: FIELDS
  });

  _exports.default = _default;
});