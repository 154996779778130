define("spyglass/components/date-picker/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    label: 'Select a date',
    date: null,
    value: Ember.computed('date', function () {
      var date = this.date;

      if (date) {
        return (0, _moment.default)(date).format('M/D/YYYY');
      }
    }),
    onSelect: function onSelect() {},
    actions: {
      select: function select(dropdown, selection) {
        dropdown.actions.close();
        this.onSelect(selection.date);
      }
    }
  });

  _exports.default = _default;
});