define("spyglass/components/delete-dropdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    label: 'Delete',
    confirmLabel: 'Yes, Delete',
    message: 'Are you sure?',
    isDeleting: false,
    onConfirm: function onConfirm() {}
  });

  _exports.default = _default;
});