define("spyglass/edit-portfolio-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r9po74Xd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"breadcrumb bg-white\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[4,\"link-to\",[\"portfolio\"],null,{\"statements\":[[1,[23,[\"appSettings\",\"uploadsSectionTitle\"]],false]],\"parameters\":[]},null],[0,\"  >  Edit File\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"container mt-30\"],[9],[0,\"\\n  \"],[1,[27,\"portfolio-upload-form\",null,[[\"upload\",\"afterSave\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"afterSave\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/edit-portfolio-upload/template.hbs"
    }
  });

  _exports.default = _default;
});