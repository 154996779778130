define("spyglass/company/edit-survey/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EeUxGzjd",
    "block": "{\"symbols\":[],\"statements\":[[4,\"scrollable-modal\",null,[[\"onClose\"],[[27,\"action\",[[22,0,[]],\"close\"],null]]],{\"statements\":[[0,\"  \"],[5,\"survey-form\",[],[[\"@surveyResults\",\"@companyName\",\"@onSubmit\",\"@isSaving\"],[[23,[\"model\",\"company\",\"vendorSurveyResults\"]],[23,[\"model\",\"company\",\"name\"]],[27,\"action\",[[22,0,[]],[27,\"perform\",[[22,0,[\"saveSurveyResults\"]]],null]],null],[22,0,[\"saveSurveyResults\",\"isRunning\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/company/edit-survey/template.hbs"
    }
  });

  _exports.default = _default;
});