define("spyglass/components/range-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9YZKKhbk",
    "block": "{\"symbols\":[\"dropdown\",\"calendar\"],\"statements\":[[4,\"basic-dropdown\",null,null,{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"trigger\"]],\"expected `dropdown.trigger` to be a contextual component but found a string. Did you mean `(component dropdown.trigger)`? ('spyglass/components/range-picker/template.hbs' @ L2:C5) \"],null]],[[\"class\"],[[27,\"concat\",[[23,[\"styleNamespace\"]],\"__label\"],null]]],{\"statements\":[[0,\"    \"],[1,[21,\"label\"],false],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"ember-power-select-status-icon\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"content\"]],\"expected `dropdown.content` to be a contextual component but found a string. Did you mean `(component dropdown.content)`? ('spyglass/components/range-picker/template.hbs' @ L7:C5) \"],null]],[[\"class\"],[[27,\"concat\",[[23,[\"styleNamespace\"]],\"__dropdown\"],null]]],{\"statements\":[[4,\"power-calendar-range\",null,[[\"class\",\"center\",\"onCenterChange\",\"selected\",\"onSelect\"],[[27,\"concat\",[[23,[\"styleNamespace\"]],\"__calendar\"],null],[23,[\"center\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"center\"]]],null]],[[\"value\"],[\"date\"]]],[23,[\"range\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"range\"]]],null]],[[\"value\"],[\"moment\"]]]]],{\"statements\":[[0,\"      \"],[1,[22,2,[\"nav\"]],false],[0,\"\\n      \"],[1,[22,2,[\"days\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-secondary btn-sm mt-10 pull-right\"],[11,\"type\",\"button\"],[9],[0,\"\\n      Done\\n    \"],[3,\"action\",[[22,0,[]],\"done\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/range-picker/template.hbs"
    }
  });

  _exports.default = _default;
});