define("spyglass/components/app-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MH/YI0/B",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__container\"]]],[9],[0,\"\\n  \"],[7,\"ul\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__menu\"]]],[9],[0,\"\\n    \"],[7,\"li\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__menu__item\"]]],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"mailto:info@sandboxindustries.com\"],[9],[0,\"Contact\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"li\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__menu__item\"]]],[9],[0,\"\\n      \"],[4,\"link-to\",[\"about\"],null,{\"statements\":[[0,\"About\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"ul\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__menu\"]]],[9],[0,\"\\n    \"],[7,\"li\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__menu__item\"]]],[9],[0,\"\\n      © \"],[1,[22,0,[\"currentYear\"]],false],[0,\" Blue Venture Fund\\n    \"],[10],[0,\"\\n    •\\n    \"],[7,\"li\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__menu__item\"]]],[9],[0,\"\\n      Built by \"],[7,\"a\"],[11,\"href\",\"https://visible.vc\"],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[9],[0,\"Visible\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/app-footer/template.hbs"
    }
  });

  _exports.default = _default;
});