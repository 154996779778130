define("spyglass/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    externalId: attr('string'),
    name: attr('string'),
    website: attr('string'),
    annualRevenue: attr('number'),
    numberOfEmployees: attr('number'),
    description: attr('string'),
    externalCreatedAt: attr('date'),
    city: attr('string'),
    state: attr('string'),
    raiseValue: attr('number'),
    bluePlanParticipation: attr('string'),
    businessStage: attr('string'),
    capitalRaisedToDate: attr('number'),
    investors: attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    stage: attr('string'),
    defenseScore: attr('number'),
    financialScore: attr('number'),
    managementBios: attr('string'),
    managementScore: attr('number'),
    latestCommentary: attr('string'),
    marketScore: attr('number'),
    productScore: attr('number'),
    strategicFit: attr('number'),
    weightedAverageScore: attr('number'),
    commentaryUpdatedOn: attr('date'),
    businessModel: attr('string'),
    portfolioCompanyCommentary: attr('string'),
    relevantFund: attr('string'),
    sourcedOn: attr('date'),
    analogs: attr(),
    logoUrl: attr('string'),
    surveyed: attr('boolean'),
    surveyCompletedOn: attr('date'),
    tags: attr(),
    contact: attr(),
    vendorSurveyResults: attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    meta: attr(),
    references: hasMany('reference', {
      async: false
    }),
    reviews: hasMany('review', {
      async: false
    }),
    teamNotes: hasMany('team-note', {
      async: false
    }),
    uploads: hasMany('upload', {
      async: false
    }),
    vendorAccesses: hasMany('vendor-access', {
      async: false
    }),
    vendorAccess: Ember.computed.readOnly('vendorAccesses.firstObject'),
    tagsList: Ember.computed('tags.[]', function () {
      return this.tags.join(', ');
    }),
    investorsList: Ember.computed('investors.[]', function () {
      return this.investors.join(', ');
    }),
    location: Ember.computed('city', 'state', function () {
      return [this.city, this.state].compact().join(', ');
    }),
    websiteWithProtocol: Ember.computed('website', function () {
      var url = this.website;

      if (url) {
        url = url.toLowerCase();

        if (url.substr(0, 4) !== 'http') {
          url = "http://".concat(url);
        }

        return url;
      }
    }),
    starRating: Ember.computed('weightedAverageScore', function () {
      var score = this.weightedAverageScore;
      return Math.ceil(score / 2);
    }),
    barChartData: Ember.computed('defenseScore', 'financialScore', 'managementScore', 'marketScore', 'productScore', 'strategicFit', function () {
      return [{
        label: 'DEFENSE',
        value: this.defenseScore
      }, {
        label: 'FINANCIAL',
        value: this.financialScore
      }, {
        label: 'MGMT.',
        value: this.managementScore
      }, {
        label: 'MARKET',
        value: this.marketScore
      }, {
        label: 'PRODUCT',
        value: this.productScore
      }, {
        label: 'STRAT. FIT',
        value: this.strategicFit
      }];
    })
  });

  _exports.default = _default;
});