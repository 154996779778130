define("spyglass/trend-uploads/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    current: Ember.inject.service(),
    queryParams: ['search', 'page'],
    search: '',
    page: 1,
    isLoading: false,
    isShowingPagination: Ember.computed.gt('totalPages', 1),
    actions: {
      searchTermChanged: function searchTermChanged(searchTerm) {
        this.setProperties({
          search: searchTerm,
          page: 1
        });
      },
      delete: function _delete(upload) {
        upload.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});