define("spyglass/components/resend-invite/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kc0adq4+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__label\"]]],[9],[0,\"\\n  Invite not accepted\\n\"],[10],[0,\"\\n\"],[7,\"a\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__action\"]]],[11,\"role\",\"button\"],[9],[0,\"\\n  Resend invite email\"],[4,\"if\",[[23,[\"resendInvite\",\"isRunning\"]]],null,{\"statements\":[[0,\"…\"]],\"parameters\":[]},null],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[27,\"perform\",[[23,[\"resendInvite\"]],[23,[\"user\"]]],null]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/resend-invite/template.hbs"
    }
  });

  _exports.default = _default;
});