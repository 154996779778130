define("spyglass/components/single-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yy9aSVmA",
    "block": "{\"symbols\":[\"option\",\"&default\"],\"statements\":[[4,\"power-select\",null,[[\"options\",\"selected\",\"placeholder\",\"searchEnabled\",\"onchange\"],[[23,[\"options\"]],[23,[\"selected\"]],[23,[\"placeholder\"]],[23,[\"searchEnabled\"]],[27,\"action\",[[22,0,[]],[23,[\"onchange\"]]],null]]],{\"statements\":[[0,\"  \"],[14,2,[[22,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/single-select/template.hbs"
    }
  });

  _exports.default = _default;
});