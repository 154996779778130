define("spyglass/components/pagination-links/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iJHpYwm4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[[23,[\"path\"]],[27,\"query-params\",null,[[\"page\"],[1]]]],[[\"disabled\",\"class\"],[[23,[\"prevPageDisabled\"]],[27,\"concat\",[[23,[\"styleNamespace\"]],\"__arrow\"],null]]],{\"statements\":[[0,\"  \"],[1,[27,\"inline-svg\",[\"arrow-left\"],null],false],[0,\"\\n  \"],[1,[27,\"inline-svg\",[\"arrow-left\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[[23,[\"path\"]],[27,\"query-params\",null,[[\"page\"],[[23,[\"prevPage\"]]]]]],[[\"disabled\",\"class\"],[[23,[\"prevPageDisabled\"]],[27,\"concat\",[[23,[\"styleNamespace\"]],\"__arrow\"],null]]],{\"statements\":[[0,\"  \"],[1,[27,\"inline-svg\",[\"arrow-left\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"span\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__count\"]]],[9],[0,\"\\n  \"],[1,[21,\"page\"],false],[0,\" of \"],[1,[21,\"totalPages\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"link-to\",[[23,[\"path\"]],[27,\"query-params\",null,[[\"page\"],[[23,[\"nextPage\"]]]]]],[[\"disabled\",\"class\"],[[23,[\"nextPageDisabled\"]],[27,\"concat\",[[23,[\"styleNamespace\"]],\"__arrow\"],null]]],{\"statements\":[[0,\"  \"],[1,[27,\"inline-svg\",[\"arrow-right\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[[23,[\"path\"]],[27,\"query-params\",null,[[\"page\"],[[23,[\"lastPage\"]]]]]],[[\"disabled\",\"class\"],[[23,[\"nextPageDisabled\"]],[27,\"concat\",[[23,[\"styleNamespace\"]],\"__arrow\"],null]]],{\"statements\":[[0,\"  \"],[1,[27,\"inline-svg\",[\"arrow-right\"],null],false],[0,\"\\n  \"],[1,[27,\"inline-svg\",[\"arrow-right\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/pagination-links/template.hbs"
    }
  });

  _exports.default = _default;
});