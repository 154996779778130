define("spyglass/components/tags-table/tag-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wbSK881n",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"__spyglass-table__cell-md\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"trend\",[23,[\"tag\",\"id\"]]],null,{\"statements\":[[0,\"    \"],[7,\"strong\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__name\"]]],[12,\"style\",[21,\"labelStyle\"]],[9],[0,\"\\n      \"],[1,[23,[\"tag\",\"label\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"tag\",\"uploadsCount\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"badge\"],[9],[1,[23,[\"tag\",\"uploadsCount\"]],false],[0,\" \"],[1,[27,\"pluralize\",[\"report\",[23,[\"tag\",\"uploadsCount\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"__spyglass-table__cell-sm\"],[9],[0,\"\\n  \"],[1,[23,[\"tag\",\"companyCount\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"__spyglass-table__cell-md\"],[9],[0,\"\\n  \"],[1,[21,\"count\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"td\"],[11,\"class\",\"__spyglass-table__cell-md __spyglass-table__cell-actions\"],[9],[0,\"\\n  \"],[1,[27,\"tag-filter-link\",null,[[\"tag\",\"class\",\"label\"],[[23,[\"tag\",\"label\"]],\"btn-inline\",\"View Companies\"]]],false],[0,\"\\n  \"],[4,\"link-to\",[\"trend\",[23,[\"tag\",\"id\"]]],[[\"class\"],[\"btn-inline\"]],{\"statements\":[[0,\"View Category\"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/tags-table/tag-row/template.hbs"
    }
  });

  _exports.default = _default;
});