define("spyglass/models/tag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    label: attr('string'),
    value: attr('string'),
    description: attr('string'),
    companyCount: attr('number'),
    uploadsCount: attr('number'),
    thirtyDayCompanyCount: attr('number'),
    sixtyDayCompanyCount: attr('number'),
    ninetyDayCompanyCount: attr('number'),
    companyCountForPeriod: function companyCountForPeriod() {
      var period = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 90;

      switch (period) {
        case 30:
          return this.thirtyDayCompanyCount;

        case 60:
          return this.sixtyDayCompanyCount;

        case 90:
          return this.ninetyDayCompanyCount;
      }
    }
  });

  _exports.default = _default;
});