define("spyglass/components/delete-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i7jlfJIz",
    "block": "{\"symbols\":[\"dropdown\"],\"statements\":[[4,\"basic-dropdown\",null,null,{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"trigger\"]],\"expected `dropdown.trigger` to be a contextual component but found a string. Did you mean `(component dropdown.trigger)`? ('spyglass/components/delete-dropdown/template.hbs' @ L2:C5) \"],null]],[[\"tagName\",\"class\"],[\"a\",[23,[\"triggerClass\"]]]],{\"statements\":[[0,\"    \"],[1,[21,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"content\"]],\"expected `dropdown.content` to be a contextual component but found a string. Did you mean `(component dropdown.content)`? ('spyglass/components/delete-dropdown/template.hbs' @ L6:C5) \"],null]],[[\"class\"],[[27,\"concat\",[[23,[\"styleNamespace\"]],\"__dropdown\"],null]]],{\"statements\":[[0,\"    \"],[1,[21,\"message\"],false],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex flex-align-right mt-10\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-secondary btn-sm\"],[11,\"type\",\"button\"],[9],[0,\"\\n        Cancel\\n      \"],[3,\"action\",[[22,0,[]],[22,1,[\"actions\",\"close\"]]]],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-primary btn-sm ml-4\"],[11,\"type\",\"button\"],[9],[0,\"\\n        \"],[1,[22,0,[\"confirmLabel\"]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"isDeleting\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"loader-overlay\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"loader\"],[9],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[3,\"action\",[[22,0,[]],[23,[\"onConfirm\"]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/delete-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});