define("spyglass/services/tooltip-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intellimap: function intellimap(category) {
      if (category === 'DEFENSE') {
        return ['<strong>Defense:</strong>', '81-100% Strong Brand/IP', '61-80% Developing Brand / Pending Patents', '41-60% Need to Develop Brand / Need to file IP', '21-40% Copy Risk / Infringement Risk', '1-20% Can\'t Break Through Competition'].join('<br>');
      } else if (category === 'FINANCIAL') {
        return ['<strong>Financial:</strong>', '81-100% Cash Position Strong / Strong Sales Growth', '61-80% Cash Position Stable / Strong Sales Growth', '41-60% Cash Position Uncertain / Strong Growth Expected', '21-40% Cash Position & Growth Uncertain / Credit Issues', '1-20% Poor Financials / Credit Issues'].join('<br>');
      } else if (category === 'MGMT.') {
        return ['<strong>Management:</strong>', '81-100% Talented Team / Easy to switch team', '61-80% Solid senior team / some recruiting needed', '41-60% Several key hires needed / Succession or Key person risk', '21-40% Struggling to attract talent', '1-20% Departures / Poor morale & culture'].join('<br>');
      } else if (category === 'MARKET') {
        return ['<strong>Market:</strong>', '81-100% Large & Growing', '61-80% Large', '41-60% Unsure / Unproven', '21-40% Less Promising', '1-20% Declining'].join('<br>');
      } else if (category === 'PRODUCT') {
        return ['<strong>Product:</strong>', '81-100% Ahead of Dev Sched / Customer Retention & Backlog', '61-80% Dev On Sched / Customers satisfied & demand strong', '41-60% Behind Dev. Sched / Cust & Demand Uncertainty', '21-40% Product Redesign / Cust Attrition', '1-20% Can\'t Dev. Prod / No Cust traction'].join('<br>');
      } else if (category === 'STRAT. FIT') {
        return ['<strong>Strategic:</strong>', '81-100% Fit with Fund Strategy / LPs Signing On', '61-80% Potential Fit with Fund Strategy / Strong LP Interest', '41-60% Possible but unlikely Fit with Strategy / Some LPs Passing', '21-40% Out of Scope / Negative LP Interest'].join('<br>');
      }
    }
  });

  _exports.default = _default;
});