define("spyglass/company/new-review/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      close: function close() {
        this.transitionToRoute('company');
      }
    }
  });

  _exports.default = _default;
});