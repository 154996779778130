define("spyglass/application/route", ["exports", "ember-data", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _emberData, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    current: Ember.inject.service(),
    error: Ember.inject.service(),
    router: Ember.inject.service(),
    metrics: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.router.on('routeDidChange', function () {
        if (_this.router.currentRouteName !== 'set-auth-token') {
          _this.metrics.trackPage('GoogleAnalytics');
        }
      });
    },
    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },
    sessionAuthenticated: function sessionAuthenticated() {
      if (!this.current.isVendor) {
        this._super.apply(this, arguments);

        this._loadCurrentUser();
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('uploadSections', this.store.peekAll('uploadSection'));
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this2 = this;

      return this.current.load().then(function (user) {
        return user && _this2.error.identify(user);
      }, function (error) {
        _this2.session.invalidate();

        throw error;
      });
    },
    actions: {
      error: function error(_error) {
        if (_error instanceof _emberData.default.NotFoundError) {
          this.intermediateTransitionTo('404', '');
          return false;
        } else {
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});