define("spyglass/components/bar-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gK6SXm7O",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[12,\"width\",[21,\"width\"]],[12,\"height\",[21,\"height\"]],[9],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"chart-tooltip\"],[12,\"style\",[21,\"tooltipStyle\"]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/bar-chart/template.hbs"
    }
  });

  _exports.default = _default;
});