define("spyglass/components/company-upload-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vjuLsviw",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[7,\"form\"],[11,\"class\",\"mt-30\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"upload\",\"isNew\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"upload-card\",null,[[\"upload\"],[[23,[\"upload\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"href\",[23,[\"upload\",\"url\"]]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[9],[0,\"\\n      \"],[1,[27,\"file-card\",null,[[\"title\",\"subtitle\",\"class\"],[[23,[\"upload\",\"name\"]],[27,\"moment-format\",[[23,[\"upload\",\"date\"]],\"M/D/YYYY\"],null],\"mt-10\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"form-group\",null,[[\"label\",\"class\",\"errors\"],[\"Name\",\"mt-20\",[27,\"get\",[[27,\"get\",[[27,\"get\",[[27,\"get\",[[22,0,[]],\"validations\"],null],\"attrs\"],null],\"upload.name\"],null],\"messages\"],null]]],{\"statements\":[[0,\"    \"],[1,[27,\"input\",null,[[\"id\",\"value\"],[[22,1,[\"inputId\"]],[23,[\"upload\",\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"form-group\",null,[[\"label\"],[\"Date\"]],{\"statements\":[[0,\"    \"],[1,[27,\"date-picker\",null,[[\"date\",\"onSelect\"],[[23,[\"upload\",\"date\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"upload\",\"date\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"form-footer\"],[9],[0,\"\\n    \"],[1,[27,\"submit-button\",null,[[\"label\",\"isSaving\",\"isInvalid\",\"class\"],[\"Save\",[23,[\"upload\",\"isSaving\"]],[23,[\"validations\",\"isInvalid\"]],\"btn btn-primary\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"save\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/company-upload-form/template.hbs"
    }
  });

  _exports.default = _default;
});