define("spyglass/dashboard/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    current: Ember.inject.service(),
    metrics: Ember.inject.service(),
    companyFilters: Ember.inject.service(),
    model: function model() {
      var companySubscriptions = this.store.findAll('company-subscription', {
        reload: true
      });
      var portfolioCompanies = this.store.query('company', {
        stage: this.companyFilters.portfolioStages
      });
      var uploads = this.store.query('upload', {
        page: {
          size: 5
        },
        upload_section_id: null
      });
      return Ember.RSVP.hash({
        companySubscriptions: companySubscriptions,
        portfolioCompanies: portfolioCompanies,
        uploads: uploads
      });
    },
    afterModel: function afterModel() {
      this.metrics.trackEvent('Mixpanel', {
        event: 'Viewed Dashboard'
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var tagSubscriptions = this.get('current.user.tagSubscriptions') || [];
      controller.set('tags', tagSubscriptions.mapBy('tag'));
      controller.set('companies', model.companySubscriptions.mapBy('company'));
      controller.set('portfolioCompanies', model.portfolioCompanies.sortBy('name'));
      controller.set('uploads', model.uploads);
    }
  });

  _exports.default = _default;
});