define("spyglass/components/score-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    placeholder: 'Wtd. Score',
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    options: [{
      min: 0,
      max: 2
    }, {
      min: 2,
      max: 4
    }, {
      min: 4,
      max: 6
    }, {
      min: 6,
      max: 8
    }, {
      min: 8,
      max: 10
    }],
    selected: Ember.computed('minScore', 'maxScore', function () {
      var minScore = this.minScore;
      var maxScore = this.maxScore;

      if (Ember.isPresent(minScore) && Ember.isPresent(maxScore)) {
        return {
          min: minScore,
          max: maxScore
        };
      }
    }),
    onSelect: function onSelect() {}
  });

  _exports.default = _default;
});