define("spyglass/components/feedback-badge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IfBOfA3M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"badge\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"value\"]],\"positive\"],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"inline-svg\",[\"icon-thumb\"],[[\"class\"],[\"icon-thumbs-up\"]]],false],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"type-color\"],[9],[1,[27,\"capitalize\",[[23,[\"value\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"value\"]],\"negative\"],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"inline-svg\",[\"icon-thumb\"],[[\"class\"],[\"icon-thumbs-down\"]]],false],[0,\"\\n    \"],[1,[27,\"capitalize\",[[23,[\"value\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"capitalize\",[[23,[\"value\"]]],null],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/feedback-badge/template.hbs"
    }
  });

  _exports.default = _default;
});