define("spyglass/components/upload-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T2gspQ4K",
    "block": "{\"symbols\":[\"dropzone\"],\"statements\":[[4,\"file-dropzone\",null,[[\"name\"],[\"uploads\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__inner\"]]],[9],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[22,1,[\"active\"]],[22,1,[\"valid\"]]],null]],null,{\"statements\":[[0,\"      Drop to upload\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"file-upload\",null,[[\"name\",\"onfileadd\"],[\"uploads\",[27,\"action\",[[22,0,[]],[27,\"perform\",[[23,[\"uploadFile\"]]],null]],null]]],{\"statements\":[[4,\"if\",[[23,[\"uploadFile\",\"isRunning\"]]],null,{\"statements\":[[0,\"          Uploading (\"],[1,[23,[\"fileQueue\",\"progress\"]],false],[0,\"%)\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"a\"],[11,\"tabindex\",\"0\"],[9],[0,\"\\n            Choose a file\\n          \"],[10],[0,\"\\n          or drag and drop it here to upload.\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"fileQueue\",\"progress\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__progress\"]]],[12,\"style\",[21,\"progressStyle\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[27,\"file-card\",null,[[\"title\",\"subtitle\",\"class\"],[[23,[\"title\"]],[27,\"moment-format\",[[23,[\"upload\",\"date\"]],\"M/D/YYYY\"],null],[27,\"if\",[[23,[\"hasNoFile\"]],\"inactive\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/upload-card/template.hbs"
    }
  });

  _exports.default = _default;
});