define("spyglass/services/current", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    metrics: Ember.inject.service(),
    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated') && !this.isVendor) {
        return this.store.queryRecord('user', {
          me: true
        }).then(function (user) {
          _this.set('user', user);

          _this.metrics.identify({
            email: user.email,
            created_at: user.createdAt,
            name: user.fullName,
            user_id: user.id,
            role: user.role,
            company_name: user.companyName,
            distinctId: user.id
          });
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },
    isVendor: Ember.computed.equal('session.data.authenticated.authenticator', 'authenticator:vendor-access')
  });

  _exports.default = _default;
});