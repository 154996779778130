define("spyglass/components/role-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    userRoles: Object.freeze([{
      value: 'regular',
      label: 'Regular User'
    }, {
      value: 'admin',
      label: 'Admin'
    }, {
      value: 'limited',
      label: 'Limited User'
    }]),
    selectedRole: Ember.computed('userRoles.[]', 'user.role', function () {
      return this.userRoles.findBy('value', this.get('user.role'));
    }),
    actions: {
      setRole: function setRole(role) {
        this.user.set('role', role.value);
      }
    }
  });

  _exports.default = _default;
});