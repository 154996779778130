define("spyglass/edit-portfolio-upload/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appSettings: Ember.inject.service(),
    actions: {
      afterSave: function afterSave() {
        this.transitionToRoute('portfolio', {
          queryParams: {
            sectionId: this.get('model.uploadSection.id')
          }
        });
      }
    }
  });

  _exports.default = _default;
});