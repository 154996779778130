define("spyglass/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    current: Ember.inject.service(),
    isShowingNavBar: Ember.computed('session.isAuthenticated', 'currentPath', function () {
      return this.get('session.isAuthenticated') && this.currentPath !== 'password-reset' && this.currentPath !== 'loading' && this.currentPath !== 'vendor-access' && this.currentPath !== 'vendor-survey';
    }),
    actions: {
      invalidateSession: function invalidateSession() {
        this.session.invalidate();
      },
      searchCompanies: function searchCompanies(searchTerm) {
        this.transitionToRoute('companies', {
          queryParams: {
            search: searchTerm,
            page: 1
          }
        });
      }
    }
  });

  _exports.default = _default;
});