define("spyglass/edit-user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nxDMkSap",
    "block": "{\"symbols\":[\"tabs\"],\"statements\":[[7,\"div\"],[11,\"class\",\"breadcrumb bg-white\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[4,\"link-to\",[\"users\"],null,{\"statements\":[[0,\"Users\"]],\"parameters\":[]},null],[0,\"  >  \"],[1,[23,[\"user\",\"displayName\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"bg-white border-bottom\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[1,[27,\"user-profile\",null,[[\"size\",\"user\",\"class\"],[\"medium\",[23,[\"user\"]],\"pt-30 pb-20\"]]],false],[0,\"\\n\\n\"],[4,\"nav-bar-tabs\",null,null,{\"statements\":[[0,\"      \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"tab\"]],\"expected `tabs.tab` to be a contextual component but found a string. Did you mean `(component tabs.tab)`? ('spyglass/edit-user/template.hbs' @ L12:C8) \"],null]],[[\"label\",\"path\"],[\"Options\",\"edit-user.index\"]]],false],[0,\"\\n      \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"tab\"]],\"expected `tabs.tab` to be a contextual component but found a string. Did you mean `(component tabs.tab)`? ('spyglass/edit-user/template.hbs' @ L16:C8) \"],null]],[[\"label\",\"path\"],[\"Subscriptions\",\"edit-user.subscriptions\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"container mt-40\"],[9],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\\n  \"],[4,\"link-to\",[\"users\"],[[\"class\"],[\"mt-20\"]],{\"statements\":[[0,\"Back to Users\"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/edit-user/template.hbs"
    }
  });

  _exports.default = _default;
});