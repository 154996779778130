define("spyglass/components/survey-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H6bc0eZL",
    "block": "{\"symbols\":[\"section\",\"field\"],\"statements\":[[7,\"h3\"],[11,\"class\",\"type-color\"],[9],[0,\"Vendor Survey for \"],[1,[21,\"companyName\"],false],[10],[0,\"\\n\\n\"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"each\",[[22,0,[\"formData\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h4\"],[11,\"class\",\"mt-30\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[22,1,[\"description\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"type-xs type-gray\"],[9],[0,\"\\n        \"],[1,[22,1,[\"description\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"mt-30\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[\"fields\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"survey-form/field\",null,[[\"field\"],[[22,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"form-footer\"],[9],[0,\"\\n    \"],[1,[27,\"submit-button\",null,[[\"label\",\"onClick\",\"isSaving\",\"class\"],[\"Submit\",[27,\"action\",[[22,0,[]],\"onSubmit\"],null],[23,[\"isSaving\"]],\"btn btn-primary\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/survey-form/template.hbs"
    }
  });

  _exports.default = _default;
});