define("spyglass/company/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3QLmRDJ8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"scrollable-modal\",null,[[\"onClose\"],[[27,\"action\",[[22,0,[]],\"close\"],null]]],{\"statements\":[[0,\"  \"],[7,\"h3\"],[11,\"class\",\"type-color\"],[9],[0,\"Upload a File\"],[10],[0,\"\\n\\n  \"],[1,[27,\"company-upload-form\",null,[[\"upload\",\"afterSave\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"close\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/company/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});