define("spyglass/components/tags-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xUhAyCCB",
    "block": "{\"symbols\":[\"tag\",\"period\"],\"statements\":[[4,\"spyglass-table\",null,null,{\"statements\":[[0,\"  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"__spyglass-table__cell-md\"],[9],[0,\"\\n        Category\\n      \"],[10],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"__spyglass-table__cell-sm\"],[9],[0,\"\\n        Companies Count\\n      \"],[10],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"__spyglass-table__cell-md __spyglass-table__cell-select\"],[9],[0,\"\\n        Sourced in previous\\n\"],[4,\"single-select\",null,[[\"options\",\"selected\",\"searchEnabled\",\"onchange\"],[[23,[\"countPeriods\"]],[23,[\"countPeriod\"]],false,[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"countPeriod\"]]],null]],null]]],{\"statements\":[[0,\"          \"],[1,[22,2,[]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        days\\n      \"],[10],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"__spyglass-table__cell-md __spyglass-table__cell-actions\"],[9],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"tags\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"tags-table/tag-row\",null,[[\"tag\",\"period\",\"maxCount\"],[[22,1,[]],[23,[\"countPeriod\"]],[23,[\"maxCount\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/tags-table/template.hbs"
    }
  });

  _exports.default = _default;
});