define("spyglass/components/submit-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    attributeBindings: ['type', 'disabled'],
    classNames: ['btn'],
    type: 'submit',
    label: 'Save',
    isSaving: false,
    isInvalid: false,
    disabled: Ember.computed.or('isInvalid', 'isSaving'),
    onClick: function onClick() {},
    click: function click() {
      this.onClick();
    }
  });

  _exports.default = _default;
});