define("spyglass/components/profile-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ig5wwDJW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"body\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h4\"],[9],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[27,\"hash\",null,[[\"title\"],[[27,\"component\",[\"profile-block/title\"],[[\"title\"],[[23,[\"title\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[21,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"p\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__body\"]]],[9],[0,\"\\n    \"],[1,[21,\"body\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/profile-block/template.hbs"
    }
  });

  _exports.default = _default;
});