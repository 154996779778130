define("spyglass/components/star-rating/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bDk5BMBF",
    "block": "{\"symbols\":[\"star\"],\"statements\":[[4,\"each\",[[23,[\"stars\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"inline-svg\",[\"star\"],[[\"class\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/star-rating/template.hbs"
    }
  });

  _exports.default = _default;
});