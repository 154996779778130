define("spyglass/components/filter-multiselect/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    beforeOptionsComponent: Ember.computed('searchEnabled', function () {
      if (this.searchEnabled) {
        return 'power-select/before-options';
      }
    })
  });

  _exports.default = _default;
});