define("spyglass/components/feedback-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EClmZ6xy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"class\",[28,[\"btn btn-sm \",[21,\"positiveClass\"]]]],[9],[0,\"\\n  \"],[1,[27,\"inline-svg\",[\"icon-thumb\"],[[\"class\"],[\"icon-thumbs-up\"]]],false],[0,\"\\n  Positive\\n\"],[3,\"action\",[[22,0,[]],\"switch\",\"positive\"]],[10],[0,\"\\n\"],[7,\"button\"],[12,\"class\",[28,[\"btn btn-sm \",[21,\"neutralClass\"]]]],[9],[0,\"\\n  Neutral\\n\"],[3,\"action\",[[22,0,[]],\"switch\",\"neutral\"]],[10],[0,\"\\n\"],[7,\"button\"],[12,\"class\",[28,[\"btn btn-sm \",[21,\"negativeClass\"]]]],[9],[0,\"\\n  \"],[1,[27,\"inline-svg\",[\"icon-thumb\"],[[\"class\"],[\"icon-thumbs-down\"]]],false],[0,\"\\n  Negative\\n\"],[3,\"action\",[[22,0,[]],\"switch\",\"negative\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/feedback-input/template.hbs"
    }
  });

  _exports.default = _default;
});