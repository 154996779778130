define("spyglass/components/filter-multiselect/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c1kHt5dm",
    "block": "{\"symbols\":[\"option\",\"&default\"],\"statements\":[[4,\"power-select-multiple\",null,[[\"options\",\"selected\",\"placeholder\",\"triggerComponent\",\"beforeOptionsComponent\",\"searchEnabled\",\"searchField\",\"onchange\"],[[23,[\"options\"]],[23,[\"selected\"]],[23,[\"placeholder\"]],\"filter-trigger\",[23,[\"beforeOptionsComponent\"]],[23,[\"searchEnabled\"]],[23,[\"searchField\"]],[27,\"action\",[[22,0,[]],[23,[\"onchange\"]]],null]]],{\"statements\":[[0,\"  \"],[14,2,[[22,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/filter-multiselect/template.hbs"
    }
  });

  _exports.default = _default;
});