define("spyglass/components/editable-block/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSaving: false,
    isEditing: false,
    isEditable: false,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var element = _this._contentElement();

        element.textContent = _this.value;
      });
    },
    onSave: function onSave() {},
    _contentElement: function _contentElement() {
      return this.element.getElementsByClassName("".concat(this.styleNamespace, "__content"))[0];
    },
    isShowingPlaceholder: Ember.computed('value', 'isEditing', function () {
      return Ember.isEmpty(this.value) && !this.isEditing;
    }),
    actions: {
      edit: function edit() {
        var _this2 = this;

        this.set('isEditing', true);
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this2._contentElement().focus();
        });
      },
      save: function save() {
        this.set('isEditing', false);

        var element = this._contentElement();

        this.onSave(element.innerText);
      },
      cancel: function cancel() {
        this.set('isEditing', false);

        var element = this._contentElement();

        element.textContent = this.value;
      }
    }
  });

  _exports.default = _default;
});