define("spyglass/references/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page'],
    page: 1,
    isLoading: false,
    isShowingPagination: Ember.computed.gt('totalPages', 1)
  });

  _exports.default = _default;
});