define("spyglass/components/pagination-links/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    page: 1,
    lastPage: Ember.computed.oneWay('totalPages'),
    nextPage: Ember.computed('page', function () {
      return this.page + 1;
    }),
    prevPage: Ember.computed('page', function () {
      return this.page - 1;
    }),
    prevPageDisabled: Ember.computed.lte('page', 1),
    nextPageDisabled: Ember.computed('page', 'totalPages', function () {
      return this.page >= this.totalPages;
    })
  });

  _exports.default = _default;
});