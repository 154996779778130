define("spyglass/components/company-reference/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "shjmBpO4",
    "block": "{\"symbols\":[\"area\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__top\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__content\"]]],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__icon\"]]],[9],[0,\"\\n      \"],[1,[27,\"inline-svg\",[\"reference\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__title\"]]],[9],[0,\"\\n      \"],[1,[23,[\"reference\",\"user\",\"fullName\"]],false],[0,\" | \"],[1,[23,[\"reference\",\"user\",\"companyName\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"company.reference\",[23,[\"reference\"]]],[[\"class\"],[\"btn btn-sm\"]],{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"type-gray type-xs\"],[9],[0,\"View\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__bottom type-sm\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"type-gray type-xs\"],[9],[0,\"Experience:\"],[10],[0,\"\\n    \"],[1,[27,\"feedback-badge\",null,[[\"value\"],[[23,[\"reference\",\"experience\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"type-gray type-xs\"],[9],[0,\"Areas:\"],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"comma-seperated\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"reference\",\"feedbackAreas\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[9],[1,[27,\"capitalize\",[[22,1,[]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/company-reference/template.hbs"
    }
  });

  _exports.default = _default;
});