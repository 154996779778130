define("spyglass/company/new-team-note/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      close: function close() {
        this.transitionToRoute('company');
      },
      open: function open() {
        this.transitionToRoute('company.team-note', this.model.id);
      }
    }
  });

  _exports.default = _default;
});