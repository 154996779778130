define("spyglass/company/file-upload/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var company = this.modelFor('company').company;
      return this.store.createRecord('upload', {
        company: company
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.currentModel.rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});