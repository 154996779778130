define("spyglass/application/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    keyForRelationship: function keyForRelationship(rawKey) {
      return Ember.String.underscore(rawKey);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // Add support for record-level meta untill https://github.com/emberjs/rfcs/pull/332 has landed
      if (requestType === 'findRecord') {
        payload.data.attributes.meta = payload.meta;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});