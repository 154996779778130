define("spyglass/vendor-survey/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3RNc0wlY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"panel-layout\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"side-panel\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"main-panel overflow-y-auto\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"main-panel__table\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"main-panel__table-cell\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"main-panel__content\"],[9],[0,\"\\n\"],[4,\"if\",[[22,0,[\"hasSubmitted\"]]],null,{\"statements\":[[0,\"            \"],[7,\"h1\"],[9],[0,\"Thank You!\"],[10],[0,\"\\n            \"],[7,\"p\"],[9],[0,\"\\n              Your response has successfully been recorded.\\n              You can update your profile any time via the initial email.\\n              Please contact \"],[7,\"a\"],[11,\"href\",\"mailto:solutioninsights@chpmail.com\"],[9],[0,\"solutioninsights@chpmail.com\"],[10],[0,\" with any further questions, comments or feedback.\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[5,\"survey-form\",[],[[\"@surveyResults\",\"@companyName\",\"@onSubmit\",\"@isSaving\"],[[23,[\"model\",\"results\"]],[23,[\"model\",\"companyName\"]],[27,\"action\",[[22,0,[]],[27,\"perform\",[[22,0,[\"saveSurveyResults\"]]],null]],null],[22,0,[\"saveSurveyResults\",\"isRunning\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/vendor-survey/template.hbs"
    }
  });

  _exports.default = _default;
});