define("spyglass/components/nav-bar-tabs/tab/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['activeClass'],
    activeClass: Ember.computed('styleNamespace', 'linkView.active', function () {
      if (this.get('linkView.active')) {
        return "".concat(this.styleNamespace, "-active");
      } else {
        return '';
      }
    }),
    didInsertElement: function didInsertElement() {
      // "Inspired" by https://github.com/alexspeller/ember-cli-active-link-wrapper
      Ember.run.scheduleOnce('afterRender', this, function () {
        var link = this.$('a')[0];
        var viewRegistry = Ember.getOwner(this).lookup('-view-registry:main');
        var linkView = viewRegistry[link.id];
        this.set('linkView', linkView);
      });
    }
  });

  _exports.default = _default;
});