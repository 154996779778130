define("spyglass/components/company-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SBm4J46V",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[27,\"avatar-company\",null,[[\"company\",\"size\",\"class\"],[[23,[\"company\"]],[23,[\"size\"]],[27,\"concat\",[[23,[\"styleNamespace\"]],\"__logo\"],null]]]],false],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__content\"]]],[9],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__title\"]]],[9],[0,\"\\n    \"],[1,[23,[\"company\",\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__subtitle\"]]],[9],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,[\"company\",\"description\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/company-profile/template.hbs"
    }
  });

  _exports.default = _default;
});