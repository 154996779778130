define("spyglass/components/scrollable-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "55rz1vJI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"modal-dialog\",null,[[\"targetAttachment\",\"translucentOverlay\",\"containerClass\",\"overlayClass\",\"wrapperClass\",\"onClose\"],[\"none\",true,[23,[\"containerClass\"]],[23,[\"overlayClass\"]],[23,[\"wrapperClass\"]],[27,\"action\",[[22,0,[]],[23,[\"onClose\"]]],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__close\"]]],[11,\"role\",\"button\"],[9],[0,\"\\n    \"],[1,[27,\"inline-svg\",[\"modal-close.svg\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"onClose\"]]]],[10],[0,\"\\n\\n  \"],[14,1,[[22,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/scrollable-modal/template.hbs"
    }
  });

  _exports.default = _default;
});