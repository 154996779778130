define("spyglass/company/new-team-note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gvp9AwXl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"scrollable-modal\",null,[[\"onClose\"],[[27,\"action\",[[22,0,[]],\"close\"],null]]],{\"statements\":[[0,\"  \"],[7,\"h3\"],[11,\"class\",\"type-color\"],[9],[0,\"New Note\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"type-gray\"],[9],[0,\"\\n    You can edit or remove this note afterwards.\\n  \"],[10],[0,\"\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n  \"],[1,[27,\"team-note-form\",null,[[\"teamNote\",\"submitLabel\",\"afterSave\"],[[23,[\"model\"]],\"Submit Note\",[27,\"action\",[[22,0,[]],\"open\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/company/new-team-note/template.hbs"
    }
  });

  _exports.default = _default;
});