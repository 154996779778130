define("spyglass/company/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    current: Ember.inject.service(),
    metrics: Ember.inject.service(),
    model: function model(params) {
      var company = this.store.findRecord('company', params.company_id);
      var references = this.store.query('reference', {
        company_id: params.company_id
      });
      var reviews = this.store.query('review', {
        company_id: params.company_id
      });
      return Ember.RSVP.hash({
        company: company,
        references: references,
        reviews: reviews
      });
    },
    afterModel: function afterModel(model) {
      this.metrics.trackEvent('Mixpanel', {
        event: 'Viewed Company',
        name: model.company.name,
        company_id: model.company.id
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('company', model.company);
      var tags = model.company.get('tags');

      if (tags && tags.length) {
        this.store.query('upload', {
          tags: tags
        }).then(function (uploads) {
          return controller.set('uploads', uploads);
        });
      }

      this.store.query('upload', {
        company_id: model.company.id
      }).then(function (uploads) {
        return controller.set('files', uploads);
      });
      var subscription = this.get('current.user.companySubscriptions').find(function (sub) {
        return sub.belongsTo('company').id() === model.company.id;
      });
      controller.set('subscription', subscription);
    },
    resetController: function resetController(controller) {
      controller.set('uploads', []);
    },
    actions: {
      print: function print() {
        window.print();
      }
    }
  });

  _exports.default = _default;
});