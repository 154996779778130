define("spyglass/companies/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    filters: Ember.inject.service('company-filters'),
    current: Ember.inject.service(),
    metrics: Ember.inject.service(),
    queryParams: ['page', 'search', 'startDate', 'endDate', 'minScore', 'maxScore', {
      tags: {
        type: 'array'
      }
    }, {
      stages: {
        type: 'array'
      }
    }, {
      business: {
        type: 'array'
      }
    }, {
      states: {
        type: 'array'
      }
    }, {
      regions: {
        type: 'array'
      }
    }, {
      industries: {
        type: 'array'
      }
    }, {
      lps: {
        type: 'array'
      }
    }],
    page: 1,
    search: '',
    startDate: null,
    endDate: null,
    minScore: null,
    maxScore: null,
    surveyed: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.items = [];
      this.tags = [];
      this.stages = [];
      this.business = [];
      this.states = [];
      this.regions = [];
      this.industries = [];
      this.lps = [];
      this.availableTags = [];
    },
    selectedStates: Ember.computed('states.[]', function () {
      var _this = this;

      return this.get('filters.states').filter(function (state) {
        return _this.states.includes(state.abbreviation);
      });
    }),
    isLoading: false,
    emptyArray: Object.freeze([]),
    // used to clear array filters in link-to
    hasDateFilter: Ember.computed.and('startDate', 'endDate'),
    hasScoreFilter: Ember.computed('minScore', 'maxScore', function () {
      return Ember.isPresent(this.minScore) && Ember.isPresent(this.maxScore);
    }),
    isShowingPagination: Ember.computed.gt('totalPages', 1),
    dateFilterText: Ember.computed('startDate', 'endDate', function () {
      var start = (0, _moment.default)(this.startDate).format('MMMM Do YYYY');
      var end = (0, _moment.default)(this.endDate).format('MMMM Do YYYY');
      return "".concat(start, " \u2013 ").concat(end);
    }),
    scoreFilterText: Ember.computed('minScore', 'maxScore', function () {
      return "Wtd. Score: ".concat(this.minScore, " \u2013 ").concat(this.maxScore);
    }),
    hasAnyFilters: Ember.computed('stages.[]', 'tags.[]', 'business.[]', 'selectedStates.[]', 'regions.[]', 'industries.[]', 'lps.[]', 'hasDateFilter', 'hasScoreFilter', function () {
      return this.get('stages.length') || this.get('tags.length') || this.get('business.length') || this.get('selectedStates.length') || this.get('regions.length') || this.get('industries.length') || this.get('lps.length') || this.hasDateFilter || this.hasScoreFilter;
    }),
    actions: {
      searchTermChanged: function searchTermChanged(searchTerm) {
        this.setProperties({
          search: searchTerm,
          page: 1
        });

        if (searchTerm.length > 2) {
          this.metrics.trackEvent('Mixpanel', {
            event: 'Searched Companies',
            search_term: searchTerm
          });
        }
      },
      setDateRange: function setDateRange(range) {
        this.setProperties({
          startDate: range.start.format('YYYY-MM-DD'),
          endDate: range.end.format('YYYY-MM-DD')
        });
      },
      selectStates: function selectStates(states) {
        this.set('states', states.mapBy('abbreviation'));
      },
      setScoreFilter: function setScoreFilter(scoreRange) {
        this.setProperties({
          minScore: scoreRange.min,
          maxScore: scoreRange.max
        });
      },
      deleteStageFilter: function deleteStageFilter(stage) {
        this.stages.removeObject(stage);
      },
      deleteTagFilter: function deleteTagFilter(tag) {
        this.tags.removeObject(tag);
      },
      deleteBusinessFilter: function deleteBusinessFilter(stage) {
        this.business.removeObject(stage);
      },
      deleteRegionFilter: function deleteRegionFilter(region) {
        this.regions.removeObject(region);
      },
      deleteIndustryFilter: function deleteIndustryFilter(indusrty) {
        this.industries.removeObject(indusrty);
      },
      deleteLPFilter: function deleteLPFilter(lp) {
        this.lps.removeObject(lp);
      },
      deleteStateFilter: function deleteStateFilter(stateName) {
        var state = this.get('filters.states').findBy('name', stateName);
        this.states.removeObject(state.abbreviation);
      },
      deleteDateFilter: function deleteDateFilter() {
        this.setProperties({
          startDate: null,
          endDate: null
        });
      },
      deleteScoreFilter: function deleteScoreFilter() {
        this.setProperties({
          minScore: null,
          maxScore: null
        });
      },
      clearFilters: function clearFilters() {
        this.setProperties({
          startDate: null,
          endDate: null,
          minScore: null,
          maxScore: null,
          stages: [],
          tags: [],
          business: [],
          states: [],
          regions: [],
          industries: [],
          lps: []
        });
      }
    }
  });

  _exports.default = _default;
});