define("spyglass/components/form-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['hasError', 'hasSuccess'],
    hasFeedback: false,
    hasError: Ember.computed('hasFeedback', 'errors.[]', function () {
      return this.hasFeedback && !Ember.isEmpty(this.errors);
    }),
    hasSuccess: Ember.computed('hasFeedback', 'errors.[]', function () {
      return this.hasFeedback && Ember.isEmpty(this.errors);
    }),
    formattedError: Ember.computed('errors.[]', function () {
      return this.errors[0];
    }),
    init: function init() {
      this.errors = [];
      this.set('api', {
        inputId: "input-".concat(Ember.guidFor(this))
      });

      this._super.apply(this, arguments);
    },
    focusOut: function focusOut() {
      this.set('hasFeedback', true);
    }
  });

  _exports.default = _default;
});