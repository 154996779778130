define("spyglass/set-auth-token/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    current: Ember.inject.service(),
    metrics: Ember.inject.service(),
    error: Ember.inject.service(),
    queryParams: {
      vendor_access_id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      var token = {
        access_token: params.token,
        token_type: 'bearer'
      };

      if (params.vendor_access_id) {
        this.session.authenticate('authenticator:vendor-access', token);
        this.transitionTo('vendor-survey', params.vendor_access_id);
      } else {
        this.session.authenticate('authenticator:sso', token);
        this.metrics.trackEvent('Mixpanel', {
          event: 'Signed In',
          sso: true
        });
        this.current.load().then(function () {
          return _this.transitionTo('dashboard');
        });
      }
    }
  });

  _exports.default = _default;
});