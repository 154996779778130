define("spyglass/components/tag-filter-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rdz/34tM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"companies\",[27,\"query-params\",null,[[\"tags\",\"page\"],[[23,[\"tagsArray\"]],1]]]],null,{\"statements\":[[1,[21,\"label\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/tag-filter-link/template.hbs"
    }
  });

  _exports.default = _default;
});