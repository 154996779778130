define("spyglass/adapters/application", ["exports", "ember-data", "spyglass/config/environment", "ember-simple-auth/mixins/data-adapter-mixin", "ember-inflector"], function (_exports, _emberData, _environment, _dataAdapterMixin, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.API_HOST,
    authorize: function authorize(xhr) {
      var _this$get = this.get('session.data.authenticated'),
          access_token = _this$get.access_token;

      xhr.setRequestHeader('Authorization', "Bearer ".concat(access_token));
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return false;
    },
    pathForType: function pathForType(modelName) {
      var underscored = Ember.String.underscore(modelName);
      return (0, _emberInflector.pluralize)(underscored);
    }
  });

  _exports.default = _default;
});