define("spyglass/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    email: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    companyName: attr('string'),
    role: attr('string', {
      defaultValue: 'regular'
    }),
    loginCount: attr('number'),
    lastLoggedInAt: attr('date'),
    createdAt: attr('date'),
    jobTitle: attr('string'),
    phoneNumber: attr('string'),
    companySubscriptions: hasMany('company-subscription', {
      async: false
    }),
    tagSubscriptions: hasMany('tag-subscription', {
      async: false
    }),
    permissions: hasMany('permission', {
      async: false
    }),
    isAdmin: Ember.computed.equal('role', 'admin'),
    isLimited: Ember.computed.equal('role', 'limited'),
    displayName: Ember.computed('fullName', 'email', function () {
      return this.fullName || this.email;
    }),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return [this.firstName, this.lastName].compact().join(' ');
    })
  });

  _exports.default = _default;
});