define("spyglass/components/file-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iQj3FVrJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__icon\"]]],[9],[0,\"\\n  \"],[1,[27,\"inline-svg\",[[23,[\"icon\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__content\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__title\"]]],[9],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[27,\"hash\",null,[[\"title\"],[[27,\"component\",[\"file-card/title\"],[[\"title\"],[[23,[\"title\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[21,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__subtitle\"]]],[9],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[27,\"hash\",null,[[\"subtitle\"],[[27,\"component\",[\"file-card/title\"],[[\"title\"],[[23,[\"subtitle\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[21,\"subtitle\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/file-card/template.hbs"
    }
  });

  _exports.default = _default;
});