define("spyglass/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XjHaYYx+",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[1,[21,\"ember-load-remover\"],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isShowingNavBar\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"nav-bar\",null,[[\"currentUser\",\"uploadSections\",\"logout\",\"searchCompanies\"],[[23,[\"current\",\"user\"]],[23,[\"uploadSections\"]],[27,\"action\",[[22,0,[]],\"invalidateSession\"],null],[27,\"action\",[[22,0,[]],\"searchCompanies\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"flash-container \",[27,\"unless\",[[23,[\"session\",\"isAuthenticated\"]],\"flash-container-logged-out\"],null]]]],[9],[0,\"\\n    \"],[1,[27,\"flash-message\",null,[[\"flash\"],[[22,1,[]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\\n\"],[4,\"if\",[[22,0,[\"isShowingNavBar\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"app-footer\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/application/template.hbs"
    }
  });

  _exports.default = _default;
});