define("spyglass/instance-initializers/honeybadger", ["exports", "spyglass/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(applicationInstance) {
    var errorService = applicationInstance.lookup('service:error');

    if (window.Honeybadger && _environment.default.HONEYBADGER_API_KEY) {
      window.Honeybadger.configure({
        apiKey: _environment.default.HONEYBADGER_API_KEY,
        environment: _environment.default.HONEYBADGER_ENVIRONMENT,
        onerror: true,
        revision: _environment.default.SOURCE_VERSION
      });

      Ember.onerror = function (error) {
        errorService.notify(error);
      };

      Ember.RSVP.configure('onerror', function (error) {
        errorService.notify(error);
      });
    }
  }

  var _default = {
    name: 'honeybadger',
    initialize: initialize
  };
  _exports.default = _default;
});