define("spyglass/components/company-profile/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['sizeClass'],
    size: 'medium',
    sizeClass: Ember.computed('size', function () {
      return {
        medium: "".concat(this.styleNamespace, "__md"),
        large: "".concat(this.styleNamespace, "__lg")
      }[this.size];
    })
  });

  _exports.default = _default;
});