define("spyglass/company/team-note/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    current: Ember.inject.service(),
    actions: {
      close: function close() {
        this.transitionToRoute('company');
      },
      delete: function _delete() {
        var _this = this;

        this.model.destroyRecord().then(function () {
          return _this.send('close');
        });
      }
    }
  });

  _exports.default = _default;
});