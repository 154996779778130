define("spyglass/components/tags-table/tag-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    opacity: Ember.computed('count', 'maxCount', function () {
      var opacity = this.count / this.maxCount;

      if (opacity > 0.2) {
        return opacity;
      } else {
        return 0.2;
      }
    }),
    labelStyle: Ember.computed('opacity', function () {
      return Ember.String.htmlSafe("opacity: ".concat(this.opacity));
    }),
    count: Ember.computed('tag.{thirtyDayCompanyCount,ninetyDayCompanyCount}', 'period', function () {
      return this.tag.companyCountForPeriod(this.period);
    })
  });

  _exports.default = _default;
});