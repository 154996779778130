define("spyglass/components/formatted-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N2KMhRE6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"date\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"moment-format\",[[23,[\"date\"]],\"M/D/YYYY\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"type-gray\"],[9],[0,\"—\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/formatted-date/template.hbs"
    }
  });

  _exports.default = _default;
});