define("spyglass/components/sort-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KCTLjmF/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"link-to\",[[23,[\"path\"]],[27,\"query-params\",null,[[\"sort\",\"search\"],[[23,[\"sortValue\"]],\"\"]]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"],[4,\"if\",[[23,[\"isDesc\"]]],null,{\"statements\":[[0,\"    ▼\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"isAsc\"]]],null,{\"statements\":[[0,\"    ▲\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/sort-link/template.hbs"
    }
  });

  _exports.default = _default;
});