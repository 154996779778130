define("spyglass/components/nav-bar-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lv1XNRWN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"ul\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__list\"]]],[9],[0,\"\\n  \"],[14,1,[[27,\"hash\",null,[[\"tab\"],[[27,\"component\",[\"nav-bar-tabs/tab\"],null]]]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/nav-bar-tabs/template.hbs"
    }
  });

  _exports.default = _default;
});