define("spyglass/authenticators/vendor-access", ["exports", "spyglass/authenticators/sso"], function (_exports, _sso) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sso.default.extend();

  _exports.default = _default;
});