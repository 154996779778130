define("spyglass/404/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wgm3tUlt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container mt-40\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"card-body padding-md\"],[9],[0,\"\\n      \"],[7,\"h3\"],[9],[0,\"Page Not Found\"],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"mt-10\"],[9],[0,\"\\n        Looks like a page with this URL does not exist.\\n        \"],[7,\"br\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"\\n        \"],[4,\"link-to\",[\"dashboard\"],null,{\"statements\":[[0,\"Back to the Dashboard\"]],\"parameters\":[]},null],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/404/template.hbs"
    }
  });

  _exports.default = _default;
});