define("spyglass/trends/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TlblC8Mp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container mt-20\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pull-right mb-20 flex flex-align-center\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"tooltip mr-8\"],[11,\"data-tooltip\",\"Growth within a trend - based on the count of companies sourced within the previous period selected.\"],[9],[0,\"\\n      ?\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"trend-growth mr-16\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"trend-growth-bar\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"flex flex-space-between\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"trend-growth-label\"],[9],[0,\"\\n          Low\\n        \"],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"trend-growth-label\"],[9],[0,\"\\n          High\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"current\",\"user\",\"isAdmin\"]]],null,{\"statements\":[[0,\"      \"],[4,\"link-to\",[\"trend-uploads\"],[[\"class\"],[\"btn btn-secondary ml-8\"]],{\"statements\":[[0,\"Manage Files\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"container mt-20\"],[9],[0,\"\\n  \"],[1,[27,\"tags-table\",null,[[\"tags\"],[[23,[\"sortedTags\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/trends/template.hbs"
    }
  });

  _exports.default = _default;
});