define("spyglass/components/list-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z7N5JGv1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__header\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__content\"]]],[9],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__content__title\"]]],[9],[0,\"\\n      ACO Medical Home or P4P\\n    \"],[10],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__content__subtitle\"]]],[9],[0,\"\\n      73 Companies\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__column\"]]],[9],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"li\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__item\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__content\"]]],[9],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__content__title\"]]],[9],[0,\"\\n      ACO Medical Home or P4P\\n    \"],[10],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__content__subtitle\"]]],[9],[0,\"\\n      73 Companies\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__column\"]]],[9],[0,\"\\n    \"],[7,\"a\"],[9],[0,\"View Latest Report\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"styleNamespace\"],\"__column\"]]],[9],[0,\"\\n    \"],[7,\"a\"],[9],[0,\"Follow\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/list-group/template.hbs"
    }
  });

  _exports.default = _default;
});