define("spyglass/company/new-review/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e0S5Zq/q",
    "block": "{\"symbols\":[],\"statements\":[[4,\"scrollable-modal\",null,[[\"onClose\"],[[27,\"action\",[[22,0,[]],\"close\"],null]]],{\"statements\":[[0,\"  \"],[7,\"h3\"],[11,\"class\",\"type-color\"],[9],[0,\"Leave a Review\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"mt-10\"],[9],[0,\"\\n    \"],[7,\"strong\"],[9],[0,\"\\n      Are you willing to provide input on your experience with this vendor?\\n      Your Blue Venture Fund colleagues will appreciate your insight.\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"type-gray\"],[9],[0,\"\\n    If so, please provide objective, factual responses to the questions below.\\n    Your Blue Venture Fund Plan or customer should be a current or former customer of the vendor.\\n    The input you provide must be relevant and based solely upon your Plan's or\\n    your customer's direct experience with the vendor.\\n  \"],[10],[0,\"\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n  \"],[1,[27,\"review-form\",null,[[\"review\",\"submitLabel\",\"afterSave\"],[[23,[\"model\"]],\"Submit Review\",[27,\"action\",[[22,0,[]],\"close\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/company/new-review/template.hbs"
    }
  });

  _exports.default = _default;
});