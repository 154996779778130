define("spyglass/components/profile-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R2hds3Te",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"type-gray\"],[9],[0,\"\\n    \"],[1,[21,\"label\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[23,[\"value\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[21,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[23,[\"tooltip\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"tooltip\"],[12,\"data-tooltip\",[21,\"tooltip\"]],[9],[0,\"?\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spyglass/components/profile-field/template.hbs"
    }
  });

  _exports.default = _default;
});