define("spyglass/references/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var query = {
        page: {
          number: params.page,
          size: 20
        }
      };
      return {
        modelPromise: this.store.query('reference', query)
      };
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('isLoading', true);
      model.modelPromise.then(function (references) {
        controller.setProperties({
          references: references,
          totalPages: references.get('meta.total_pages'),
          total: references.get('meta.total'),
          isLoading: false
        });
      });
    }
  });

  _exports.default = _default;
});