define("spyglass/components/survey-form/field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var planStatusOptions = ['Pilot', 'Available for all customers', 'Because of a specific customer(s) request(s)', 'Strategic partnership', 'Fully-insured business', 'Self-insured business', 'HMO business', 'Government programs business'];

  var _default = Ember.Component.extend({
    tagName: '',
    planStatusOptions: planStatusOptions,
    actions: {
      addCollectionValue: function addCollectionValue(field) {
        field.value.addObject({});
      },
      removeCollectionValue: function removeCollectionValue(field, value) {
        field.value.removeObject(value);
      }
    }
  });

  _exports.default = _default;
});